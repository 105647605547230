import * as React from 'react'
import Layout from '../../layout'
import { MediumHeader } from '../../components/shared/header'
import {
  AboutContainer,
  Address,
  Contact,
  ContactContainer,
  ContactHeadline,
  ContactInfoContainer,
  ContactWrapper,
  OpeningHours,
  OpeningHoursContainer,
  OpeningHoursHeadline,
  Place,
  Street,
  ContactText,
  ButtonContainer,
} from './styles'
import Button, { ButtonTypes } from '../../components/shared/button'
import { graphql } from 'gatsby'


export default class ContactPage extends React.Component {
  render() {
    return (
      <Layout location={'/contact'}>
        <MediumHeader srcSet={this.props.data.headerBackground.childImageSharp.fluid.srcSet}/>

        <ContactWrapper>
          <ContactContainer>

            <ContactHeadline>
              Kontaktieren Sie uns
            </ContactHeadline>

            <ContactText>
              Wir unterstützen Sie bei Ihrer individuellen Lösung. Sprechen Sie mit uns.
            </ContactText>

            <ContactInfoContainer>
              <AboutContainer>
                <OpeningHoursHeadline>Anschrift</OpeningHoursHeadline>
                <Address>
                  <Street>Pfatterstraße 1</Street>
                  <Place>93098 Mintraching / Tiefbrunn</Place>
                </Address>
                <Contact href={'tel:09406 9403-0'}>Tel.: 09406 9403-0</Contact>
                <Contact href={'fax:09406 9403-0'}>Fax.: 09406 9403-20</Contact>
                <Contact
                  href={'mailto:service@amann-betonbau.de'}>service@amann-betonbau.de</Contact>
              </AboutContainer>
              <OpeningHoursContainer>
                <OpeningHoursHeadline>Unsere Öffnungszeiten</OpeningHoursHeadline>
                <OpeningHours>
                  <tbody>
                  <tr>
                    <td>Montag bis Donnerstag</td>
                    <td>7.30 Uhr - 17.00 Uhr</td>
                  </tr>
                  <tr>
                    <td>Freitag</td>
                    <td>7.30 Uhr - 13.00 Uhr</td>
                  </tr>
                  </tbody>
                </OpeningHours>
              </OpeningHoursContainer>
            </ContactInfoContainer>

            <ButtonContainer>
              <Button href={'tel:09406 9403-0'}>Rufen Sie uns an</Button>
              <Button href={'mailto:service@amann-betonbau.de'} type={ButtonTypes.Secondary}>E-Mail an uns</Button>
            </ButtonContainer>
          </ContactContainer>
        </ContactWrapper>
      </Layout>
    )
  }
}

export const ContactQuery = graphql`{
    headerBackground: file(name: {eq: "contact-header"}) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
}
`
