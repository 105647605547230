import styled from 'styled-components'
import { Device } from '../../components/core/responsive'
import { Colors } from '../../components/core/colors'
import { Fonts } from '../../components/core/fonts'
import { motion } from 'framer-motion'

export const ContactWrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
  z-index: 2;
  align-items: center;
`

export const ContactContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 920px;
  margin: 0 10px;
  flex-direction: column;
  top: -100px;
  background: ${Colors.white};
  padding: 20px;
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
  z-index: 2;
  border-radius: 3px;
`

export const AboutContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1 50%;
  margin-bottom: 20px;
  color: ${Colors.text};
`

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (${Device.laptop}){
    flex-direction: row;
  }
`

export const ContactHeadline = styled.h2`
  position: relative;
  font-weight: 900;
  font-size: 1.75rem;
  font-family: ${Fonts.default};
  font-stretch: condensed;
  margin-bottom: 8px;
`

export const ContactText = styled.p`
  position: relative;
  font-size: 1.25rem;
  font-weight: 300;
  color: ${Colors.primary};
  margin-bottom: 48px;
  font-family: ${Fonts.default};
`

export const OpeningHoursHeadline = styled.h3`
  position: relative;
  font-weight: 700;
  font-size: 1.25rem;
  font-family: ${Fonts.default};
  font-stretch: condensed;
    color: ${Colors.text};
  margin-bottom: 12px;
`

export const Address = styled.div`
  margin-bottom: 20px;
  line-height: 1.2rem;
`

export const Street = styled.p`
    color: ${Colors.text};
`

export const Place = styled.p`
    color: ${Colors.text};
`

export const Contact = styled.a`
    cursor: pointer;
    margin-bottom: 8px;
    
    &:hover{
      color: ${Colors.primary};
    }
`

export const OpeningHoursContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1 50%;
  margin-bottom: 24px;
  
  @media (${Device.laptop}){
    margin-bottom: 0;
  }
`


export const OpeningHours = styled.table`
   align-items: center;
   color: ${Colors.text};
   width: 100%;
   border-spacing: 0 10px;
   border-collapse: inherit;
`

export const ButtonContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  @media (${Device.laptop}){
    flex-direction: row;
  }
  
  & > a{
    cursor: pointer;
    min-width: 200px;
    margin-bottom: 12px;
  
    @media (${Device.laptop}){
      margin-right: 12px;
      margin-bottom: 0;
    }
      
    &:last-of-type{
      margin-right: 0;
    }
  }
`
